import React,{useState} from 'react';
import {Link} from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styled,{css} from 'styled-components'
import Slide from '../elements/Slide'
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      >  
      </div>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      >  
      </div>
    );
  }
const SliderContainer = styled.div`
    width:95%;
    margin:auto;
    padding-bottom:5%;
    @media screen and (max-width: 1040px) {
      width:100%;
    }
`;
const Carousel = (props) => {
    // const [tagState, setTagState] = useState(false);
    let arr=[];
    const handleTagState = (object) => {
        // setTagState(tagState => true);  
        arr.push(object)
    };
    const settings = {
        dots: false,
        infinite: false,
        focusOnSelect: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        swipeToSlide: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className:"service-slider",
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 479,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      };
    return (
        
        <SliderContainer>
            <Slider {...settings}>            
                {props.data.allWpPost.nodes.map(({ node },index) => (                    
                    props.data.allWpPost.nodes[index].tags.nodes.map(({ tag },tagIndex) => (
                        props.data.allWpPost.nodes[index].tags.nodes[tagIndex].name==props.tag?
                            handleTagState(props.data.allWpPost.nodes[index])
                        :""
                    ))                    
                ))}  
                {arr.map(({ node },index) => (
                    <div key={index}> 
                        <Slide data={arr[index]} ></Slide>                        
                    </div>
                ))}  
          </Slider>  
        </SliderContainer>
    );
};

export default Carousel;