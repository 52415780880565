import React, { useState } from 'react';
import styled, { css } from 'styled-components'

//Elements
import Title from '../elements/Title'
import Slider from '../elements/ServiceSlider'
import ChevronUp from '../images/chevron-up.svg'
import ChevronDown from '../images/chevron-down.svg'

const AccordionWrap = styled.div`
    height:auto;
    width:100%;
    margin-bottom:1.45em;
    margin-top:${props => props.top}
`;
const AccordionTitleWrap = styled.div`
    display:flex;
    justify-content: flex-start;
    width:fit-content;
    align-items: center;
`;
const AccordionIcon = styled.img`
    width:50px;
    height:50px;
    z-index:10; 
    flex-shrink:0;
    @media screen and (max-width: 479px) {
      width:40px;
    height:40px;
    }
`;
const AccordionIconWrap = styled.div`
  z-index:10;
  padding-right:2em;
  @media screen and (max-width: 479px) {
    // width:20% !important;
    padding-right:1em !important;
  }
`;
const ChevronWrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    justify-content: center;
    align-items:center;
    margin-bottom:22px;
    transition: all 0.3s ease;  
    transform: translateX(32px);
    width:0px;
    opacity:0;
    ${props => props.clicked && css`
        transform: translateX(0px);
        width:22px;
        padding-right:2.5em;
        opacity:1;
        @media screen and (max-width: 479px) {
          width:15% !important;
          padding-right:1em !important;
        }
    `}   
    ${props => props.hovered && css`
        transform: translateX(0px);
        width:22px;
        padding-right:2.5em;
        opacity:1;
        @media screen and (max-width: 479px) {
          width:15% !important;
          padding-right:1em !important;
        }
    `}   
`;
const ChevronIconUpWrap = styled.div`
    width: 22px;
    height: 8px;
    transition: all 0.3s ease;
    display:flex;
    ${props => props.clicked && css`
        transform: translateY(9px);
    `}    
    cursor:pointer;
`;
const ChevronIconDownWrap = styled.div`
    width: 22px;
    height: 8px;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    display:flex;
    display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
    align-items: flex-end;
    -webkit-align-items: flex-end;
  -ms-flex-align: end;
    ${props => props.clicked && css`
        transform: translateY(-9px);
        -webkit-transform:translateY(-9px);
      -moz-transform:translateY(-9px);
      -ms-transform:translateY(-9px);
    `}    
`;
const ChevronUpIcon = styled.img`
    margin-bottom:0;
    width: 22px;
    height: 22px;
`;
const ChevronDownIcon = styled.img`
    margin-bottom:0;
    width: 22px;
    height: 22px;
`;
const Ellipse = styled.div`
    width: 6px;
    height: 6px;
    background-color: #4ab9e9;
    border-radius: 50%;
    opacity:1;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    ${props => props.clicked && css`
        opacity:0;
    `}    
 `;

const AccordionContentWrap = styled.div`
    width:100%;
    height:auto;
    max-height:0;
    overflow:hidden;
    transition: all 0.6s ease-out;
    opacity:1;
    ${props => props.clicked && css`
        max-height:100vw;
        opacity:1;
        @media screen and (max-width: 991px) {
          max-height:100vw;
        }
        @media screen and (max-width: 479px) {
          max-height:800px;
        }
    `} 
 `;
const AccordionTitleDiv = styled.div`
      cursor:pointer;
      @media screen and (max-width: 479px) {
        min-width:65%;
      }
`;
const Accordion = (props) => {
  const [hoverState, setHoverState] = useState(false);
  const [clickedState, setClickedState] = useState(false);
  const toggleHover = () => {
    setHoverState(hoverState => !hoverState);
  };
  const toggleAccordion = () => {
    setClickedState(clickedState => !clickedState);
  };
  return (
    <AccordionWrap top={props.top}>
      <AccordionTitleWrap onMouseOver={toggleHover} onMouseOut={toggleHover}>
        <ChevronWrap clicked={clickedState} hovered={hoverState} onClick={toggleAccordion}>
          <ChevronIconUpWrap clicked={clickedState}>
            <ChevronUpIcon src={ChevronUp} />
          </ChevronIconUpWrap>
          <Ellipse clicked={clickedState} />
          <ChevronIconDownWrap clicked={clickedState}>
            <ChevronDownIcon src={ChevronDown} />
          </ChevronIconDownWrap>
        </ChevronWrap>
        <AccordionIconWrap>
          <AccordionIcon src={props.image} />
        </AccordionIconWrap>

        <AccordionTitleDiv onClick={toggleAccordion}>
          <Title size="xs" width="max-content" color="white" textTransform="lowercase" fontFamily="Century Gothic, sans-serif">{props.title}</Title>
        </AccordionTitleDiv>
      </AccordionTitleWrap>
      <AccordionContentWrap clicked={clickedState}>
        <Slider width="95%" data={props.data} tag={props.tag}></Slider>
      </AccordionContentWrap>
    </AccordionWrap>
  );
};

export default Accordion;