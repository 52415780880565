import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutUs from "../views/About/About"
const About = ({ data }) => (
  <Layout>
    <SEO title="About Us " url="https://www.kwazi.in/about" />
    <AboutUs data={data}></AboutUs>
  </Layout>
)

export default About
export const query = graphql`
query {
  allWpPost(sort: {order: ASC, fields: date}) {
      nodes {
      title
      content
      date
      slug
      excerpt
      summary {
          summary
        }
      categories {
          nodes {
          slug
          name
          }
      }
      tags {
          nodes {
          slug
          name
          }
      }
      featuredImage {
          node {
          mediaItemUrl
          }
      }
      }
  }
}
`
