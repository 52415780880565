import React, { useState } from 'react';
import useSound from 'use-sound';
import styled, { css } from 'styled-components'

import Kwazi from '../static/sounds/kwazi.mp3';
import HeadPhones from '../images/headphones.svg'
const HeadphoneIcon = styled.img`
    width:4em;
    height:auto;
    position:absolute;
    transition:all 0.4s ease;
    margin:auto;
    left:0%;
    right:0%;
    bottom:0;
    top:0;
    cursor:pointer;
`;
const HeadPhoneWrap = styled.div`
    position:relative;
    width:100px;
    height:100px;
    background:#4ab9e9;
    border-radius:50%;
    display:flex;
    transition:all 0.4s ease;
    justify-content: center;
    align-items:center;
    transform: scale(0.45);
    left: 10%;    
    top: 12%;
    ${props => props.hovered && css`
        transform: scale(1);
        left: 0% ;    
        top: 0% ;
    `}
`;
const TranslateBlock = styled.div`
    position:relative;
    width:100px;
    height:100px;
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction:column;
`;
const SoundContainer = styled.div`
    width:100%;
    display:flex;
    justify-content: center;
    align-items:center;
`;
const KwaziSound = () => {
  const [play, stop] = useSound('https://kwazifiles.s3.us-east-2.amazonaws.com/Kwazi+Audio+File.mp3', { interrupt: true, });
  const [hoverState, setHoverState] = useState(false);
  const toggleHover = () => {
    setHoverState(hoverState => !hoverState);
  };
  return (
    <SoundContainer>
      <TranslateBlock>
        <HeadPhoneWrap hovered={hoverState} />
        <HeadphoneIcon src={HeadPhones} hovered={hoverState} onClick={play} onMouseEnter={toggleHover} onMouseLeave={toggleHover} />
      </TranslateBlock>
    </SoundContainer>
  );
};

export default KwaziSound;