import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import slimg from '../images/img25.jpg';
import ReadMore from '../elements/ReadMore';

const SlideContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width:100%;
    padding: 50px 0% 0 0%;
    margin-bottom:5%:
    @media screen and (max-width: 767px) {
       
    }
    @media screen and (max-width: 479px) {
        padding: 0px 0% 0 0px;
        margin-bottom: 2em;
    }
`;
const CaseTitle = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;    
    color: ${props => props.color || '#4ab9e9'};
    padding-bottom:20px;
    margin-bottom: 0;
    width:100%;
    z-index:0;
    @media screen and (min-width: 1920px) {
        width:100%;
    }
    @media screen and (max-width: 991px) {
        font-size: 20px;
    }
    @media screen and (max-width: 479px) {
        font-size: 20px;
    }
`;
const SlideWrap = styled.div`
    position:relative;
    display:flex;
    height:auto;
    justify-content:flex-end;
    align-items:flex-end;
    width:auto;
    z-index:0;
    height:368px;
    margin-bottom:20px;
    @media screen and (max-width: 479px) {
        justify-content:center;
        align-items:flex-end;
    }
`;
const BlueRect = styled.div`
    width: 260px;
    height: 324px;
    background:#4AB9E9;
    transform:translate(0%,0%);
    position:absolute;
    transition:all 0.4s ease;
    -webkit-transition:all 0.4s ease;
    border-radius:20px;
    ${props => props.hovered && css`
        width: 310px;
        height: 368px;
    `} 
    @media screen and (max-width: 479px) {
        width: 230px;
        height: 294px;
        ${props => props.hovered && css`
        width: 310px;
        height: 338px;
    `} 
    @media screen and (max-width: 350px) {
        width: 210px;
        height: 274px;
        ${props => props.hovered && css`
            height: 318px;
        `} 
    }
    }
`;
const Frame = styled.div`
    width: 260px;
    height: 324px;
    border: solid 1px #fff;
    position:absolute;
    transform:translate(3%,-3%);
    transition:all 0.4s ease;
    -webkit-transition:all 0.4s ease;
    border-radius:20px;
    ${props => props.hovered && css`
        width: 310px;
        height: 368px;
    `} 
    @media screen and (max-width: 479px) {
        width: 230px;
        height: 294px;
        ${props => props.hovered && css`
            height: 338px;
        `} 
    }
    @media screen and (max-width: 350px) {
        width: 220px;
        height: 274px;
        ${props => props.hovered && css`
            height: 328px;
        `} 
    }
`;

const Img = styled.img`
    -webkit-backface-visibility: hidden; 
    position:  relative;
    width:273px;
    height: 332px;
    margin-right:30px;
    margin-left:auto;
    border-radius:20px;
    transition:all 0.4s ease;
    object-fit:cover;
    transform:translate(-20px,-0px);
    box-shadow: 22px 3px 35px -22px rgba(0,0,0,0.63);
    ${props => props.hovered && css`
        width: 300px;
        height: 357px;
        transform:translate(30px,12px);
        box-shadow: 0px 18px 36px -16px rgba(0,0,0,0.74);
    `}
    @media screen and (max-width: 479px) {
        width:243px;
        height: 302px;
        transform:translate(0px,-0px);
        ${props => props.hovered && css`
            height: 327px;
            transform:translate(20px,12px);
        `}
    }
    @media screen and (max-width: 350px) {
        width:223px;
        height: 282px;
        transform:translate(0px,-0px);
        ${props => props.hovered && css`
            height: 307px;
            transform:translate(20px,12px);
        `}
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width:320px;
    @media screen and (max-width: 479px) {
        width:260px;
    }
`;
const Slide = (props) => {
    const [hoverState, setHoverState] = useState(false);
    const HoverShow = () => {
        setHoverState(hoverState => true);
    };
    const Hoverhide = () => {
        setHoverState(hoverState => false);
    };
    useEffect(() => {
        setHoverState(false);
    }, []);
    const tileImage = props.data.summary.tileImage;
    return (
        <SlideContainer onMouseEnter={HoverShow} onMouseLeave={Hoverhide}>
            <SlideWrap >
                <BlueRect hovered={hoverState} />
                <Frame hovered={hoverState} />
                {
                    tileImage ?
                        <Img src={tileImage.mediaItemUrl} alt={tileImage.altText} hovered={hoverState} /> :
                        <Img src={props.data.featuredImage.node.mediaItemUrl} hovered={hoverState} />
                }
            </SlideWrap>
            <TitleContainer>
                {props.homePage == "true" ?
                    <CaseTitle color="#fff">{props.data.title}</CaseTitle>
                    :
                    <CaseTitle>{props.data.title}</CaseTitle>
                }
                <ReadMore link={props.data.categories.nodes[0].name == "Case Study" ? "/project/" + props.data.slug + "/" : "/journal/" + props.data.slug + "/"} align="left" paddingLeft="1%">read more</ReadMore>
            </TitleContainer>
        </SlideContainer>
    );
};

export default Slide;