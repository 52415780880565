import React from 'react';
import Lottie from "lottie-react";
import { useLottie } from "lottie-react";
import Kmp4 from "../../images/Comp 1_1.mp4"
import kAnimation from "../../components/lottie/data.json"
import { Link } from 'gatsby'
import styled from 'styled-components'
import Title from '../../elements/Title'
import Para from '../../elements/Paragraph'
import Sound from '../../elements/KwaziSound'
import Accordion from '../../components/Accordion'
import Contact from '../../views/Contact/Contact'
import ReadMore from '../../elements/ReadMore';
import ReactPlayer from 'react-player'
//icons
import BrandStrategy from '../../images/brandStragety.svg'
import Architecture from '../../images/prodDev.svg'
import Design from '../../images/design.svg'
import Internal from '../../images/internal.svg'
import Communications from '../../images/communications.svg'

import { ContentBlock, Verse } from '../../static/css/style'
const Video = styled.div`
    width:100%;
    height:40em;
    margin-bottom:67px;
    margin-top:80px;
    @media screen and (max-width: 991px) {
        margin-top:40px;
      }
    @media screen and (max-width: 479px) {
        height:auto;
      }
`;
const TitleDiv = styled.div`
    margin-top:12vw;
    margin-bottom:7em;
    @media screen and (min-width: 1920px) {
        margin-top:19vw;
      }
      @media screen and (max-width: 479px) {
        margin-bottom:0vw;
      }
`;
const BannerContentWrap = styled.div`
    width:100%;
    margin:auto;
    display:grid;
    grid-template-columns:1.25fr 0.75fr;
    grid-template-rows:auto;
    @media screen and (max-width: 991px) {
        display:flex;
        flex-direction:column-reverse;
        gap:20px;
    }
    @media screen and (max-width: 479px) {
        margin-top:0px;
        justify-content:flex-start;
        align-items:flex-start;
        margin-bottom:40px;
     }
`;
const BannerContentContainer = styled.div`
      width:100%;
      display:flex;
      flex-direction:column;
      justify-content:flex-start;
      align-items:flex-start;
`;
const BigKContainer = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-end;
    @media screen and (max-width: 479px) {
        justify-ceontent:flex-start;
        align-items:flex-start;
     }
`;
const BigK = styled.div`
    font-family: 'Begum', sans-serif;
    font-size: 435px;
    font-weight: 600;
    text-transform:lowercase;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-bottom:0;
    @media screen and (max-width: 991px) {
        font-size: 335px;
    }
    @media screen and (max-width: 479px) {
        font-size: 205px;
    }
`;
const BigKGif = styled.img`
    width:100%;
    height:auto;
`;

const About = (props) => {
    const options = {
        animationData: kAnimation,
        loop: true,
        autoplay: true
    };

    const { View } = useLottie(options);
    return (
        <div className="bodyBlack">
            <ContentBlock >
                <BannerContentWrap>
                    <BannerContentContainer>
                        {/* <Title size="md" color="white" weight="normal" lineHeight="100%" bottom="20px">our practice...</Title> */}
                        <Para LineHeight="1.71" color="white">Kwa:zi is a multi-disciplinary and excellence-led design organization.</Para>
                        <Para LineHeight="1.71" color="white">We find our passion in creating impactful design that express impactful ideas, products, and brands. Our work in branding, UI/UX, communication & campaign design, strategy and spatial design, finds meaning through partnership, with our clients and their brands.</Para>
                        <Para LineHeight="1.71" color="white">We pride ourselves on being agile yet focussed - always ready to work on challenges that have us stretching for better. Through our practices, we attempt to integrate skill and knowledge with exposure and experience. Our team of diverse thinkers - designers, entrepreneurs, artists, writers, technologists, allows us to optimise our approach to create effective and impressive outcomes.</Para>
                        <Para LineHeight="1.71" color="white">k?</Para>
                    </BannerContentContainer>
                    <BigKContainer>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: `
                                    <video
                                    loop
                                    muted
                                    autoplay
                                    playsinline
                                    width="100%"
                                    height="100%"
                                    >
                                    <source src="https://kwazifiles.s3.us-east-2.amazonaws.com/Comp+1_1.mp4" type="video/mp4" />
                                    </video>`
                            }}
                            className="videoPlayer"
                            style={{ width: "100%", height: "100%" }}
                        />
                        {/* <ReactPlayer playing={true} loop={true} playsinline={true} controls={false} width={"100%"} height={"100%"} url={"https://kwazifiles.s3.us-east-2.amazonaws.com/Comp+1_1.mp4"}>

                        </ReactPlayer > */}

                        {/* <BigK>k</BigK> */}
                    </BigKContainer>
                </BannerContentWrap>
                {/* <TitleDiv>
                    <Title size="xl" weight="normal" lineHeight="1.33" color="#191919" >Good design is for most. Great design is for everyone.</Title>
                </TitleDiv> */}

                <Video>
                    {/* <ReactPlayer playing={true} loop={true} playsinline={true} controls={false} width={"100%"} height={"100%"} url={"https://kwazifiles.s3.us-east-2.amazonaws.com/gif.mp4"}>

                    </ReactPlayer > */}
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `
                                    <video
                                    loop
                                    muted
                                    autoplay
                                    playsinline
                                    width="100%"
                                    height="100%"
                                    >
                                    <source src="https://kwazifiles.s3.us-east-2.amazonaws.com/gif.mp4" type="video/mp4" />
                                    </video>`
                        }}
                        className="videoPlayer"
                        style={{ width: "100%", height: "100%" }}
                    />
                </Video>
                <Para LineHeight="1.71" color="white" top="20px">Kwa:zi is symbolic of everything aspirational or ambitious, reminiscent of ideas written on paper but etched deeper, in your imagination. ‘Almost’ is commonly misconceived as a final state but to us, it is fluid, paying homage to the indomitable spirit of optimism.</Para>
                <Sound></Sound>
                <Para size="lg" color="rgba(255,255,255,0.5)" marginBottom="10%" LineHeight="1.55" fontWeight="bold" align="center">Kwa:what? Hear it to say it right.</Para>
                <Title size="xl" weight="bold" bottom="5%" color="white" lineHeight="100%">genesis...</Title>
                <Para LineHeight="1.71" color="white">Kwa:zi started out with the realisation that branding and design agencies need to change how they value design. Organisations intrinsically desire better outcomes across products, brands or designs. Although they forget that the detail that goes into building these outcomes must be driven by customer insight and design research. Agency and Organisation must collaborate effectively to create value for all stakeholders in creating a more responsible tomorrow.</Para>
                <Para LineHeight="1.71" color="white">We have established an alternative to corporate studios which can’t really deliver a customised hands-on experience for the client that truly needs it. Whether it is a start-up, NPO or business, they all need <em>partners in growth</em>.</Para>
                <Para LineHeight="1" marginBottom="13%" color="white">
                    Feeling some synergy? Figure out whether you can find yourself floating in&nbsp;
                    <Verse to="/inspiration/">
                        the kwa:ziverse
                        <div className="verseBorder" />
                    </Verse>.
                </Para>
                <Title size="xl" color="white" weight="bold" bottom="5%" lineHeight="100%">our services...</Title>
                <Accordion title="Brand Strategy" image={BrandStrategy} tag="Brand Strategy" data={props.data} top="40px" />
                <Accordion title="Brand Architecture & Design" image={Architecture} tag="Brand Architecture & Design" data={props.data} />
                <Accordion title="UI/UX Design & Development" image={Design} tag="UI/UX Design & Development" data={props.data} />
                <Accordion title="Internal Branding & Design Thinking" image={Internal} tag="Internal Branding & Design Thinking" data={props.data} />
                <Accordion title="Communications & Outreach" image={Communications} tag="Communications & Outreach" data={props.data} />
                <Contact heading="let's talk?" />
            </ContentBlock>
        </div>
    );
};
export default About;
